import React from 'react';
import { Box, BoxProps, Flex, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { getFloatAnimation } from '../../libs/utils';
import FeatureIcon from './FeatureIcon';

export const QuickFeatures = React.memo(({ ...props }: BoxProps) => {
  return (
    <Flex as='section' align='flex-start' direction={{ base: 'column', lg: 'row' }} justify='space-between' {...props}>
      <VStack w='100%'>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={{ base: 2, md: 6 }} textAlign='left'>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg='bg-surface-landing'
            borderRadius='lg'
            animation={getFloatAnimation()}
          >
            <Flex justifyContent='space-between' w='100%'>
              <Heading as='h2' size='xs' mb={4}>
                Flat Files
              </Heading>
              <FeatureIcon variant='flatfile' color='bg-default-inverted' />
            </Flex>
            <Text color='bg-default-inverted'>Support CSV in all its flavours</Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg='bg-surface-landing'
            borderRadius='lg'
            animation={getFloatAnimation()}
          >
            <Flex justifyContent='space-between' w='100%'>
              <Heading as='h2' size='xs' mb={4}>
                Quick
              </Heading>
              <FeatureIcon variant='quick' color='bg-default-inverted' />
            </Flex>
            <Text color='bg-default-inverted'>Setup API destinations in seconds</Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg='bg-surface-landing'
            borderRadius='lg'
            animation={getFloatAnimation()}
          >
            <Flex justifyContent='space-between' w='100%'>
              <Flex justifyContent='space-between' w='100%'>
                <Heading as='h2' size='xs' mb={4} mr={1}>
                  Low Code
                </Heading>
                <FeatureIcon variant='no code' color='bg-default-inverted' />
              </Flex>
            </Flex>
            <Text color='bg-default-inverted'>Simplify integrations with our mapping editor</Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg='bg-surface-landing'
            borderRadius='lg'
            animation={getFloatAnimation()}
          >
            <Flex justifyContent='space-between' w='100%'>
              <Heading as='h2' size='xs' mb={4}>
                SFTP as a Service
              </Heading>
              <FeatureIcon variant='sftp' color='bg-default-inverted' />
            </Flex>
            <Text color='bg-default-inverted'>Cost effective and Easy SFTP setup</Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg='bg-surface-landing'
            borderRadius='lg'
            animation={getFloatAnimation()}
          >
            <Flex justifyContent='space-between' w='100%'>
              <Heading as='h2' size='xs' mb={4}>
                Data Quarantining
              </Heading>
              <FeatureIcon variant='reports' color='bg-default-inverted' />
            </Flex>
            <Text color='bg-default-inverted'>Ring-fence bad data for easy reprocessing</Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg='bg-surface-landing'
            borderRadius='lg'
            animation={getFloatAnimation()}
          >
            <Flex justifyContent='space-between' w='100%'>
              <Heading as='h2' size='xs' mb={4}>
                Scalable Migration
              </Heading>
              <FeatureIcon variant='scale' color='bg-default-inverted' />
            </Flex>
            <Text color='bg-default-inverted'>Import large CSV files</Text>
          </Box>
        </SimpleGrid>
      </VStack>
    </Flex>
  );
});

QuickFeatures.displayName = 'QuickFeatures';
